import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'
import AppMain from '@/layout/components/AppMain'
// import projectRouter from '@/project/APP_PROJECT_NAME/router' //动态引入不同的项目路由文件

Vue.use(Router)
// console.log("projectRouter",projectRouter)
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect/index'],resolve)
      }
    ]
  },
  {
    path: '/login',
    component: (resolve) => require(['@/views/login/index'],resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error-page/404'],resolve),
    hidden: true
  },
  {
    path: '/401',
    component: (resolve) => require(['@/views/error-page/401'],resolve),
    hidden: true
  },
  {
    path: '/preview',
    component: (resolve) => require(['@/components/Editor/preview'],resolve),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    meta: {title: '工作台', icon: 'el-icon-s-home', affix: true},
    children: [
      {
        path: 'dashboard',
        component: (resolve) => require(['@/views/dashboard/index'],resolve),
        name: 'Dashboard',
        meta: {title: '工作台', affix: true}
      },
    ]
  },
]

export const asyncRoutes = []

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({y: 0}),
  routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
