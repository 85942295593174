<template>
  <section class="app-main" :style="key == '/dashboard' ? 'background:#eeeeee' : ''">
    <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view v-if='$route.meta.keepAlive'></router-view>
        <!--  :key="key" -->
      </keep-alive>
    </transition>

    <transition name="fade-transform" mode="out-in">
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </transition>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      // console.log(this.$store.state.tagsView, '======================')
      // return this.$store.state.tagsView.cachedViews
      return ['ParentView', ...this.$store.state.tagsView.cachedViews]
    },
    key() {
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  // min-height: calc(100vh - 50px);
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.fixed-header+.app-main {
  padding-top: 40px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header+.app-main {
    padding-top: 84px;
  }
}


</style>

<style lang="scss">
// fix css style bug in open el-dialog
// .el-popup-parent--hidden {
//   .fixed-header {
//     padding-right: 15px;
//   }
// }
</style>
